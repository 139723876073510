<template>
    <div>
        <v-text-field 
            v-model="value"
            outlined
            :error="errors && errors.length > 0"
            :class="errors && errors.length ? 'hasError' : ''"
            :label="required ? label + '*' : label" 
            @keydown.enter.prevent=""
            @keypress="emitKeypress"
            @keyup.delete="emitKeypress"
            :disabled="disabled || false"
            placeholder=" "
            :id="'search' + guid1"
            :hide-details="true">
            <template v-slot:prepend-inner><v-icon>fas fa-search</v-icon></template>
            <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon></template>
        </v-text-field>

        <ErrorMessages :errors="errors"></ErrorMessages>
    </div>
</template>

<script>
import { newGuid, googleMapsService, sanitizeAddress } from '@/scripts/helper';

export default {
    name: 'GooglePlaces',
    props: {
        label: String,
        rules: String,
        required: Boolean,
        disabled: Boolean,
        addressType: String,
        errors: {
            type: Array,
            default: () => []
        }
    },
    emits: [
        'change',
        'keypress'
    ],
    data() {
        return {
            value: null,
            autocomplete: null,
            guid1: null,
        }
    },
    methods: {
        sanitizeAddress,
        placeSearch(place) {
            if (!place || !place.formatted_address) return;
            googleMapsService.geocode(place.formatted_address)
            .then((response) => {
                if (response.results.length) {
                    const data = response.results[0];
                    const address = {       
                        StreetNumber: this.sanitizeAddress(googleMapsService.getGoogleResultStreetNumber(data)),
                        StreetName: this.sanitizeAddress(googleMapsService.getGoogleResultStreetName(data)),                
                        City: this.sanitizeAddress(googleMapsService.getGoogleResultCity(data)),
                        State: this.sanitizeAddress(googleMapsService.getGoogleResultState(data)),
                        PostalCode: this.sanitizeAddress(googleMapsService.getGoogleResultPostalCode(data)),
                        CountryCode: this.sanitizeAddress(googleMapsService.getGoogleResultCountryCode(data)),
                        County: this.sanitizeAddress(googleMapsService.getGoogleResultCounty(data)),
                    };

                    this.emitValue(address);
                }
            })
        },
        emitValue(address) {
            this.$emit('change', address)       
        },
        emitKeypress(event) {
            this.$emit('keypress', event);
        },
    },
    created() {
        this.guid1 = newGuid();
    },
    mounted() {
        this.$nextTick(() => {
            let input = document.getElementById('search' + this.guid1)
            this.autocomplete = new google.maps.places.Autocomplete(input);
            this.autocomplete.setTypes(['address']);
            this.autocomplete.setComponentRestrictions({country: ['US']});

            this.autocomplete.addListener('place_changed', () => {
                let place = this.autocomplete.getPlace();
                this.value = place.formatted_address;
                this.placeSearch(place);
            });
        });
        
    }
}
</script>

<style scoped>
    ::v-deep input::placeholder {color: transparent!important;}

    ::v-deep .v-input--is-label-active .v-input__prepend-inner,
    ::v-deep .v-input--is-dirty .v-input__prepend-inner, 
    ::v-deep .v-input--is-focused .v-input__prepend-inner,
    ::v-deep .v-input--is-label-active .v-input__prepend-inner,
    ::v-deep .v-input--is-dirty .v-input__prepend-inner, 
    ::v-deep .v-input--is-focused .v-input__prepend-inner {
        margin-top: 22px;
    }
</style>
